import React from "react";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://fabioaromanini.com"
      >
        fabioaromanini.com
      </a>
    </div>
  );
};

export default Footer;
