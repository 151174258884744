import "./Text.css";

const Text = () => {
  return (
    <div className="Text">
      Thousands of years ago, the first man discovered how to make fire. He was
      probably burned at the stake he had taught his brothers to light. He was
      considered an evildoer who had dealt with a demon mankind dreaded. But
      thereafter men had fire to keep them warm, to cook their food, to light
      their caves. He had left them a gift they had not conceived and
      <b> he had lifted darkness off the earth.</b>
      <br />
      <br />
      Centuries later, the first man invented the wheel. He was probably torn on
      the rack he had taught his brothers to build. He was considered a
      transgressor who ventured into forbidden territory. But thereafter, men
      could travel past any horizon. He had left them a gift they had not
      conceived and<b> he had opened the roads of the world.</b>
      <br />
      <br />
      That man, <b>the unsubmissive and first,</b> stands in the opening chapter
      of every legend mankind has recorded about its beginning. Prometheus was
      chained to a rock and torn by vultures - because he had stolen the fire of
      the gods. Adam was condemned to suffer - because he had eaten the fruit of
      the tree of knowledge. Whatever the legend, somewhere in the shadows of
      its memory mankind knew that its glory began with one and that that one
      paid for his courage.
      <br />
      <br />
      <div className="highlight">
        Throughout the centuries there were men who took first steps down new
        roads armed with nothing but their own vision.
      </div>
      <br />
      <br />
      Their goals differed, but they all had this in common: that the step was
      first, the road new, the vision unborrowed, and the response they received
      - hatred. The great creators - the thinkers, the artists, the scientists,
      the inventors - stood alone against the men of their time. Every great new
      thought was opposed. Every great new invention was denounced. The first
      motor was considered foolish. The airplane was considered impossible. The
      power loom was considered vicious. Anesthesia was considered sinful. But
      the men of unborrowed vision went ahead. They fought, they suffered and
      they paid. <b>But they won.</b>
      <br />
      <br />
      No creator was prompted by a desire to serve his brothers, for his
      brothers rejected the gift he offered and that gift destroyed the slothful
      routine of their lives. <b>His truth was his only motive.</b> His own
      truth, and his own work to achieve it in his own way. A symphony, a book,
      an engine, a philosophy, an airplane or a building - that was his goal and
      his life. Not those who heard, read, operated, believed, flew or inhabited
      the thing he had created. <b>The creation, not its users.</b> The
      creation, not the benefits others derived from it. The creation which gave
      form to his truth. He held his truth above all things and against all men.
      <br />
      <br />
      His vision, his strength, his courage came from his own spirit.{" "}
      <b>A man's spirit, however, is his self</b>. That entity which is his
      consciousness. To think, to feel, to judge, to act are functions of the
      ego.
      <br />
      <br />
      The creators were not selfless. It is the whole secret of their power -
      that it was self-sufficient, self-motivated, self-generated. A first
      cause, a fount of energy, a life force, a Prime Mover.{" "}
      <b>The creator served nothing and no one. He lived for himself.</b> And
      only by living for himself was he able to achieve the things which are the
      glory of mankind. Such is the <b>nature of achievement.</b>
      <br />
      <br />
      Man cannot survive except through his mind. He comes on earth unarmed. His
      brain is his only weapon. Animals obtain food by force. Man has no claws,
      no fangs, no horns, no great strength of muscle. He must plant his food or
      hunt it. To plant, he needs a process of thought. To hunt, he needs
      weapons, and to make weapons - a process of thought. From this simplest
      necessity to the highest religious abstraction, from the wheel to the
      skyscraper, everything we are and everything we have comes from a single
      attribute of man - the function of his reasoning mind.
      <br />
      <br />
      But the mind is an attribute of the individual.{" "}
      <b>There is no such thing as a collective brain.</b> There is no such
      thing as a collective thought. An agreement reached by a group of men is
      only a compromise or an average drawn upon many individual thoughts. It is
      a secondary consequence. The primary act - the process of reason - must be
      performed by each man alone. We can divide a meal among many men. We
      cannot digest it in a collective stomach. No man can use his lungs to
      breathe for another man. No man can use his brain to think for another.
      All the functions of body and spirit are private. They cannot be shared or
      transferred.
      <br />
      <br /> We inherit the products of the thought of other men. We inherit the
      wheel. We make a cart. The cart becomes an automobile. The automobile
      becomes an airplane. But all through the process what we receive from
      others is only the end product of their thinking. The moving force is the
      creative faculty which takes this product as material, uses it and
      originates the next step. This creative faculty cannot be given or
      received, shared or borrowed. It belongs to single, individual men. That
      which it creates is the property of the creator. Men learn from one
      another. But all learning is only the exchange of material. No man can
      give another the capacity to think.{" "}
      <b>Yet that capacity is our only means of survival.</b>
      <br />
      <br />
      Nothing is given to man on earth.{" "}
      <b>Everything he needs has to be produced.</b>
      And here man faces his basic alternative: he can survive in only one of
      two ways - by the independent work of his own mind or as a parasite fed by
      the minds of others. The creator originates. The parasite borrows. The
      creator faces nature alone. The parasite faces nature through an
      intermediary.
      <br />
      <br />
      <div className="highlight">
        The creator's concern is the conquest of nature. The parasite's concern
        is the conquest of men.
      </div>
      <br />
      <br />
      The creator lives for his work. He needs no other men. His primary goal is
      within himself. The parasite lives second-hand. He needs others. Others
      become his prime motive.
      <br />
      <br />
      The basic need of the creator is independence. The reasoning mind cannot
      work under any form of compulsion. It cannot be curbed, sacrificed or
      subordinated to any consideration whatsoever. It demands total
      independence in function and in motive.{" "}
      <b>To a creator, all relations with men are secondary.</b>
      <br />
      <br />
      The basic need of the second-hander is to secure his ties with men in
      order to be fed. He places relations first. He declares that man exists in
      order to serve others. <b>He preaches altruism.</b>
      <br />
      <br />
      <div className="highlight">
        Altruism is the doctrine which demands that man live for others and
        place others above self.
      </div>
      <br />
      <br />
      No man can live for another. He cannot share his spirit just as he cannot
      share his body. But the second-hander has used altruism as a weapon of
      exploitation and reversed the base of mankind's moral principles. Men have
      been taught every precept that destroys the creator.{" "}
      <b>Men have been taught dependence as a virtue.</b>
      <br />
      <br />
      The man who attempts to live for others is a dependent. He is a parasite
      in motive and makes parasites of those he serves.{" "}
      <b>The relationship produces nothing but mutual corruption.</b> It is
      impossible in concept. The nearest approach to it in reality - the man who
      lives to serve others - is the slave. If physical slavery is repulsive,
      how much more repulsive is the concept of servility of the spirit? The
      conquered slave has a vestige of honor. He has the merit of having
      resisted and of considering his condition evil. But the man who enslaves
      himself voluntarily in the name of love is the basest of creatures. He
      degrades the dignity of man and he degrades the conception of love. But
      this is the essence of altruism.
      <br />
      <br />
      Men have been taught that the highest virtue is not to achieve, but to
      give. <b>Yet one cannot give that which has not been created.</b> Creation
      comes before distribution - or there will be nothing to distribute. The
      need of the creator comes before the need of any possible beneficiary. Yet
      we are taught to admire the second-hander who dispenses gifts he has not
      produced above the man who made the gifts possible.{" "}
      <b>We praise an act of charity. We shrug at an act of achievement.</b>
      <br />
      <br />
      Men have been taught that their first concern is to relieve the sufferings
      of others. <b>But suffering is a disease.</b> Should one come upon it, one
      tries to give relief and assistance. To make that the highest test of
      virtue is to make suffering the most important part of life. Then man must
      wish to see others suffer - in order that he may be virtuous. Such is the
      nature of altruism.{" "}
      <b>
        The creator is not concerned with disease, but with life. Yet the work
        of the creators has eliminated one form of disease after another,
      </b>{" "}
      in man's body and spirit, and brought more relief from suffering than any
      altruist could ever conceive.
      <br />
      <br />
      Men have been taught that it is a virtue to agree with others. But the
      creator is the man who disagrees. Men have been taught that it is a virtue
      to swim with the current. But the creator is the man who goes against the
      current. Men have been taught that it is a virtue to stand together.{" "}
      <b>But the creator is the man who stands alone.</b>
      <br />
      <br />
      Men have been taught that the ego is the synonym of evil, and selflessness
      the ideal of virtue. But the creator is the egotist in the absolute sense,
      and the selfless man is the one who does not think, feel, judge or act.
      These are functions of the self.
      <br />
      <br />
      Here the basic reversal is most deadly. The issue has been perverted and
      man has been left no alternative - and no freedom. As poles of good and
      evil, he was offered two conceptions: egotism and altruism. Egotism was
      held to mean the sacrifice of others to self. Altruism - the sacrifice of
      self to others. This tied man irrevocably to other men and left him
      nothing but a choice of pain: his own pain borne for the sake of others or
      pain inflicted upon others for the sake of self. When it was added that
      man must find joy in self-immolation, the trap was closed.{" "}
      <b>
        Man was forced to accept masochism as his ideal - under the threat that
        sadism was his only alternative. This was the greatest fraud ever
        perpetrated on mankind.
      </b>{" "}
      This was the device by which dependence and suffering were perpetuated as
      fundamentals of life.
      <br />
      <br />
      <div className="highlight">
        The choice is not self-sacrifice or domination. The choice is
        independence or dependence.
      </div>
      <br />
      <br />
      The code of the creator or the code of the second-hander. This is the
      basic issue. It rests upon the alternative of life or death. The code of
      the creator is built on the needs of the reasoning mind which allows man
      to survive. The code of the second-hander is built on the needs of a mind
      incapable of survival. All that which proceeds from man's independent ego
      is good. All that which proceeds from man's dependence upon men is evil.
      <br />
      <br />
      The egotist in the absolute sense is not the man who sacrifices others.{" "}
      <b>
        He is the man who stands above the need of using others in any manner.
      </b>{" "}
      He does not function through them. He is not concerned with them in any
      primary matter. Not in his aim, not in his motive, not in his thinking,
      not in his desires, not in the source of his energy. He does not exist for
      any other man - and he asks no other man to exist for him.{" "}
      <b>
        This is the only form of brotherhood and mutual respect possible between
        men.
      </b>
      <br />
      <br />
      Degrees of ability vary, but the basic principle remains the same: the
      degree of a man's independence, initiative and personal love for his work
      determines his talent as a worker and his worth as a man.{" "}
      <b>Independence is the only gauge of human virtue and value.</b> What a
      man is and makes of himself; not what he has or hasn't done for others.
      There is no substitute for personal dignity. There is no standard of
      personal dignity except independence.
      <br />
      <br />
      In all proper relationships there is no sacrifice of anyone to anyone. An
      architect needs clients, but he does not subordinate his work to their
      wishes. They need him, but they do not order a house just to give him a
      commission. Men exchange their work by free, mutual consent to mutual
      advantage when their personal interests agree and they both desire the
      exchange. If they do not desire it, they are not forced to deal with each
      other. They seek further.{" "}
      <b>This is the only possible form of relationship between equals.</b>{" "}
      Anything else is a relation of slave to master, or victim to executioner.
      <br />
      <br />
      No work is ever done collectively, by a majority decision. Every creative
      job is achieved under the guidance of a single individual thought. An
      architect requires a great many men to erect his building. But he does not
      ask them to vote on his design. They work together by free agreement and
      each is free in his proper function. An architect uses steel, glass,
      concrete, produced by others. But the materials remain just so much steel,
      glass and concrete until he touches them. What he does with them is his
      individual product and his individual property.{" "}
      <b>This is the only pattern for proper co-operation among men.</b>
      <br />
      <br />
      The first right on earth is the right of the ego. Man's first duty is to
      himself. His moral law is never to place his prime goal within the persons
      of others.{" "}
      <b>
        His moral obligation is to do what he wishes, provided his wish does not
        depend primarily upon other men.
      </b>{" "}
      This includes the whole sphere of his creative faculty, his thinking, his
      work. But it does not include the sphere of the gangster, the altruist and
      the dictator.
      <br />
      <br />A man thinks and works alone. A man cannot rob, exploit or
      rule-alone. Robbery, exploitation and ruling presuppose victims. They
      imply dependence. They are the province of the second-hander.
      <br />
      <br />
      Rulers of men are not egotists. They create nothing. They exist entirely
      through the persons of others. Their goal is in their subjects, in the
      activity of enslaving. They are as dependent as the beggar, the social
      worker and the bandit. The form of dependence does not matter.
      <br />
      <br />
      But men were taught to regard second-handers - tyrants, emperors,
      dictators - as exponents of egotism.{" "}
      <b>
        By this fraud they were made to destroy the ego, themselves and others.
        The purpose of the fraud was to destroy the creators. Or to harness
        them.
      </b>{" "}
      Which is a synonym.
      <br />
      <br />
      From the beginning of history, the two antagonists have stood face to
      face: the creator and the second-hander.{" "}
      <b>
        When the first creator invented the wheel, the first second-hander
        responded.
      </b>{" "}
      He invented altruism.
      <br />
      <br />
      The creator - denied, opposed, persecuted, exploited - went on, moved
      forward and carried all humanity along on his energy. The second-hander
      contributed nothing to the process except the impediments. The contest has
      another name: <b>the individual against the collective.</b>
      <br />
      <br />
      The 'common good' of a collective - a race, a class, a state - was the
      claim and justification of every tyranny ever established over men. Every
      major horror of history was committed in the name of an altruistic motive.
      Has any act of selfishness ever equaled the carnage perpetrated by
      disciples of altruism? Does the fault lie in men's hypocrisy or in the
      nature of the principle? The most dreadful butchers were the most sincere.
      They believed in the perfect society reached through the guillotine and
      the firing squad.{" "}
      <b>
        Nobody questioned their right to murder since they were murdering for an
        altruistic purpose.
      </b>{" "}
      It was accepted that man must be sacrificed for other men. Actors change,
      but the course of the tragedy remains the same. A humanitarian who starts
      with declarations of love for mankind and ends with a sea of blood. It
      goes on and{" "}
      <b>
        will go on so long as men believe that an action is good if it is
        unselfish.
      </b>{" "}
      That permits the altruist to act and forces his victims to bear it. The
      leaders of collectivist movements ask nothing for themselves. But observe
      the results.
      <br />
      <br />
      The only good which men can do to one another and the only statement of
      their proper relationship is - Hands off!
      <br />
      <br />
      Now observe the results of a society built on the principle of
      individualism. This, our country (United States of America). The noblest
      country in the history of men.{" "}
      <b>
        The country of greatest achievement, greatest prosperity, greatest
        freedom.
      </b>{" "}
      This country was not based on selfless service, sacrifice, renunciation or
      any precept of altruism. It was based on a man's right to the pursuit of
      happiness. His own happiness. Not anyone else's. A private, personal,
      selfish motive. Look at the results. Look into your own conscience.
      <br />
      <br />
      It is an ancient conflict. Men have come close to the truth, but it was
      destroyed each time and one civilization fell after another. Civilization
      is the progress toward a society of privacy. The savage's whole existence
      is public, ruled by the laws of his tribe.
      <div className="highlight">
        Civilization is the process of setting man free from men.
      </div>
      <br />
      <br />
      Now, in our age, collectivism, the rule of the second-hander and
      second-rater, the ancient monster, has broken loose and is running amuck.
      It has brought men to a level of intellectual indecency never equaled on
      earth. It has reached a scale of horror without precedent.{" "}
      <b>It has poisoned every mind.</b> It has swallowed most of Europe. It is
      engulfing our country...
    </div>
  );
};
export default Text;
