import React from "react";

import "./Title.css";

const Title = () => {
  return (
    <header className="title">
      <h1>Praise to the Men of Vision</h1>
      <h2>
        from The Fountainhead <br />
        by Ayn Rand, 1943
      </h2>
      <hr />
    </header>
  );
};

export default Title;
