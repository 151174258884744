import React from "react";
import "./App.css";

import Hero from "./Hero/Hero";
import Text from "./Text/Text";
import Title from "./Title/Title";
import Footer from "./Footer/Footer";

function App() {
  return (
    <div className="App">
      <Hero />
      <div className="container">
        <Title />
        <Text />
      </div>
      <Footer />
    </div>
  );
}

export default App;
